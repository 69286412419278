<!-- ステータスを表示するボックス -->

<template>
  <div class="box">
    <dl class="status-data">
      <StatusData :item="item" :value="value"/>
      <StatusData item="PT" :value="point"/>
    </dl>
  </div>
</template>

<script>
import StatusData from '../atoms/StatusData'

export default {
  name: "StatusPanel",
  components: {
    StatusData
  },
  props: {
    item: String,
    value: Number,
    point: Number
  }
}

</script>

<style scoped>
  .status-data {
    width: 100%;
  }
  .box {
    width: auto;
    height: auto;
    padding: 5px;
    vertical-align: middle;
    display: table-cell;
    cursor: pointer;
    user-select: none;
    border-radius: 4pt;
    margin: 0px 2px;
  }
</style>